import { handleErrorWithSentry } from '@sentry/sveltekit';
import * as Sentry from '@sentry/sveltekit';
import posthog from 'posthog-js';

Sentry.init({
	dsn: 'https://40f45c65f0b046421a44d5abd347d1ad@o382946.ingest.us.sentry.io/4508053980446720',
	environment: import.meta.env.MODE,
	integrations: [
		posthog.sentryIntegration({
			organization: 'who-targets-me',
			projectId: 4508053980446720,
			severityAllowList: ['error', 'info'] // optional: here is set to handle captureMessage (info) and captureException (error)
		})
	],
	tracesSampleRate: 1.0
});

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry();
