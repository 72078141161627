import * as client_hooks from '../../../src/hooks.client.ts';
import * as universal_hooks from '../../../src/hooks.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16')
];

export const server_loads = [0];

export const dictionary = {
		"/": [2],
		"/hohenheim": [3],
		"/installation": [4],
		"/landing": [5],
		"/offboarding-survey": [6],
		"/onboarding-survey": [7],
		"/pilot": [8],
		"/pre-study": [9],
		"/sentry-example": [10],
		"/study": [11],
		"/thank-you": [12],
		"/torontomu-complete": [14],
		"/torontomu-screen-out": [15],
		"/torontomu": [13],
		"/unknown-study": [16]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: universal_hooks.reroute || (() => {})
};

export { default as root } from '../root.svelte';